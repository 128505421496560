import React, { useState } from 'react';

import allReleases from './data/releases.json';
import brazilianReleases from './data/releases-br.json';

const mappedReleases = {
  all: allReleases,
  // br: brazilianReleases
};

function Card({ spotify, genres }) {
  const { images, release_date, album_type, external_urls, name, artists } = spotify;
  const image = images[0].url;

  return (
    
      <div class="card">
        <a href={ external_urls.spotify } target="_blank" rel="noopener noreferrer">
          <img src={image} class="card-image" alt={name} />
          <h2 class="card-title">{ name }</h2>

          <div class="card-overlay">
            <h3 class="card-artists">{ artists.map(it => it.name).join(' • ') }</h3>
            <h4 class="card-release-date">{ release_date }</h4>
            <span class="card-type">{ genres.length > 0 ? genres.join(' • ') : 'Not specified'}</span>
          </div>
        </a>
      </div>
    

  )
}
function App() {
  const [ releasesFrom, setReleasesFrom ] = useState('all');

  function setRelease(releaseFrom) {
    return function set() {
      setReleasesFrom(releaseFrom);
    }
  }

  return (
    <div class="app">
      <h1>Spotify Releases</h1>
      <div class="releases-choices">
        <a href="/#" onClick={setRelease('all')} class={`release ${releasesFrom === 'all' ? 'active' : ''}`}>All</a>
        {/* <a href="/#" onClick={setRelease('br')} class={`release ${releasesFrom === 'br' ? 'active' : ''}`}>Brazil</a> */}
      </div>

      <div class="list">
        {
          mappedReleases[releasesFrom].map(it => (
            <Card {...it} key={it.id} />
          ))
        }
      </div>
    </div>
  );
}

export default App;
